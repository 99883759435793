<template>
    <div>
        <component-selector></component-selector>
        <component-type-tabs @componentSelectionTypeChanged="selectionTypeChange"></component-type-tabs>
        <common-properties v-if="componentPropertyTab == 'common'" @submitPropData="submitPropData"
                           @showUpload="showUpload" @showResetConfirm="showResetConfirm" :propertyResetData="propertyResetData"></common-properties>
        <component-properties v-else @submitPropData="submitPropData" @showUpload="showUpload"
                              @showResetConfirm="showResetConfirm"></component-properties>
        <input type="file" ref="fileInut" style="display: none" @change="handleUploadFile"/>
        <popup-prop-reset-confirm @reset="propReset"></popup-prop-reset-confirm>
    </div>
</template>

<script>
  import ComponentSelector from './ComponentSelector'
  import ComponentTypeTabs from './ComponentTypeTabs'
  import TrainerService from '../../services/TrainerService'
  import ComponentProperties from './ComponentProperties'
  import CommonProperties from './CommonProperties'
  import NotificationService from '../../services/NotificationService'
  import TTInstanceService from '../../services/TTInstanceService'
  import PopupPropResetConfirm from './PropResetConfirmPopup'

  export default {
    name: 'ComponentPropertyManager',
    data () {
      return {
        trainerService: new TrainerService(),
        notificationService: new NotificationService(),
        instanceService: new TTInstanceService(),
        componentPropertyTab: '',
        toBeUpdateCompId: '',
        propertyResetData: []
      }
    },
    components: {
      PopupPropResetConfirm,
      ComponentSelector,
      ComponentTypeTabs,
      ComponentProperties,
      CommonProperties
    },
    computed: {
      component () {
        return this.$store.getters.getComponent
      },
      trainer () {
        return this.$store.getters.getTrainer
      }
    },
    watch: {},
    methods: {
      selectionTypeChange (selectionType) {
        this.componentPropertyTab = selectionType
      },
      submitPropData (field, data, componentId, type = '') {
        console.log('submitPropData', field, data, componentId, type)
        this.toBeUpdateCompId = componentId
        this.$store.commit('setLoading', true)
        this.submitTrainerComponentData(field, data, type).then(() => {
          this.$store.commit('setPreviewCssInjectRequired', true)
          this.notificationService.successNotification('Property updated successfully!')
        }).finally(() => {
          this.$store.commit('setLoading', false)
        })
      },
      submitTrainerComponentData (field, data, type) {
        return this.instanceService.submitComponentData(this.$store.getters.getInstance.id, this.toBeUpdateCompId, field, data, type)
      },
      showUpload (field, componentId) {
        this.toBeUpdateCompId = componentId
        this.$refs.fileInut.value = null
        this.selectedPropertyField = field
        this.$refs.fileInut.click()
      },
      showResetConfirm (field, componentId) {
        this.$store.commit('propResetPopup/setComponentId', componentId)
        this.$store.commit('propResetPopup/setField', field)
        this.$store.commit('propResetPopup/setShowStatus', true)
      },
      handleUploadFile (event) {
        let componentProperty = this.$store.getters['components/getConfigByIdAndField'](this.toBeUpdateCompId, this.selectedPropertyField)
        const configWidth = componentProperty.config ? componentProperty.config.width : null
        const configHeight = componentProperty.config ? componentProperty.config.height : null
        const heightCheckCriteria = componentProperty.config && componentProperty.config.height_check_criteria ? componentProperty.config.height_check_criteria : '==='
        const widthCheckCriteria = componentProperty.config && componentProperty.config.width_check_criteria ? componentProperty.config.width_check_criteria : '==='
        const acceptedExtensions = componentProperty.config && componentProperty.config.accepted_file_types ? 
        componentProperty.config.accepted_file_types.split(',') : 
        ['jpg', 'jpeg',  'png', 'svg', 'bmp', 'gif']
        const validationMessages = componentProperty.config && componentProperty.config.validation_messages ? componentProperty.config.validation_messages : {}

        let file = event.target.files[0]
        const fileName = file.name;
        const fileExtension = fileName.split('.').pop();
        if (acceptedExtensions && !acceptedExtensions.includes(fileExtension)) {
          if(validationMessages.extension) {
            this.notificationService.errorNotification(validationMessages.extension)
          } else {
            this.notificationService.errorNotification('Upload an image in ' + acceptedExtensions.map(ext => `.${ext}`).join(', ') + ' format')
          }
          return
        }
        let image = new Image()
        image.src = window.URL.createObjectURL(file)
        image.onload = () => {
          let imgWidth = image.width
          let imgHeight = image.height

          if (
            configWidth && !this.compare(imgWidth, configWidth, widthCheckCriteria) || 
            configHeight && !this.compare(imgHeight, configHeight, heightCheckCriteria)
          ) {
            // Note: only defined active cases here. if there are other, add them here
            if(widthCheckCriteria === '<=') {
              this.notificationService.errorNotification('Invalid dimensions provided. (Height: Exactly ' + configHeight + 'px, Width: Should not exceed ' + configWidth + 'px)')
            } else {
              this.notificationService.errorNotification('Invalid dimensions provided. (Width: ' + configWidth + 'px, Height: ' + configHeight + 'px)')
            }
          } else {
            // in KB
            let fileSize = Math.round((file.size / 1024))
            // limit added as 2MB with buffer amount
            if (fileSize > 2048) {
              this.notificationService.errorNotification('File size must be less than 2MB.')
            } else {
              // upload image
              this.$store.commit('setLoading', true)
              this.submitTrainerComponentData(this.selectedPropertyField, file, 'image').then(() => {
                this.$store.commit('setPreviewRefreshRequired', true)
                this.$store.commit('setComponentPropRefreshRequired', this.toBeUpdateCompId)
                this.notificationService.successNotification('Image uploaded successfully!')
                this.setPropResetData(this.selectedPropertyField, this.toBeUpdateCompId, true)
              }).finally(() => {
                this.$store.commit('setLoading', false)
              })
            }
        }
        // if image could not be loaded
        image.onerror = () => {
          this.notificationService.errorNotification('Invalid file provided or type not supported.')
        }
      }
    },
    propReset (field, componentId) {
      this.setPropResetData(field, componentId, false)
    },
    setPropResetData (field, componentId, showResetValue) {
      let itemFound = false
      this.propertyResetData.forEach(item => {
        if (item.field === field && item.componentId === componentId) {
          item.showReset = showResetValue
          itemFound = true
        }
      })
      if(!itemFound) {
        this.propertyResetData.push({
          field: field,
          componentId: componentId,
          showReset: showResetValue
        })
      }
    }
  }
}
</script>

<style scoped>

</style>
