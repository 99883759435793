<template>
    <div class="toning-setting__with-colors">
      <div class="toning-setting__color-lable">
        <p>{{label}}</p>
        <p class="toning-setting__tile-note" v-if="note">{{note}}</p>
      </div>

        <div>
            <div class="form-group-picker" v-if="selections.length <= 0">
                <input type="color" v-model="color" @change="changed"/>
                <information-button :image="informationImage" v-if="informationImage"></information-button>
            </div>

            <div class="form-group-picker" v-if="selections.length > 0">
                <select v-model="color" @change="changed">
                    <option :value="option.value" v-for="(option, key) in selections" :key="key">{{option.text}}</option>
                </select>
                <information-button :image="informationImage" v-if="informationImage"></information-button>
            </div>
        </div>
    </div>
</template>

<script>
import InformationButton from './InformationButton'
export default {
  name: 'ColorTile',
  props: ['data', 'component'],
  components: {
    InformationButton
  },
  data () {
    return {
      selections: [],
      label: '',
      color: '',
      field: '',
      note: '',
      informationImage: ''
    }
  },
  mounted () {
    this.label = this.data.label
    this.color = 'data' in this.data ? this.data.data.color : this.data.config.default
    this.field = this.data.field
    this.note = this.data.config.note
    this.informationImage = 'instructions' in this.data.config ? this.data.config.instructions : ''
    this.selections = 'selections' in this.data.config ? this.data.config.selections : []
  },
  methods: {
    changed () {
      this.$emit('changed', this.data.field, this.color, this.component, 'color')
    }
  }
}
</script>

<style scoped>
</style>
