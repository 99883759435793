<template>
    <div>
        <div class="side-scroll">
          <div class="side-scroll--inside-wrap">
            <div class="toning-setting__with-icon-title" v-if="colorProperties.length > 0">
                <img src="../../assets/images/colors2.png" alt/>
                <div class="title">Colors</div>
            </div>

            <color-tile
                :component="colorProp.componentId"
                :data="colorProp"
                v-for="colorProp in colorProperties"
                :key="colorProp.field"
                @changed="submitPropData"
            ></color-tile>

            <div class="toning-setting__with-icon-title" v-if="imageProperties.length > 0">
                <img src="../../assets/images/images-icon2.png" alt/>
                <div class="title">Images</div>
            </div>

            <image-tile
                :component="imageProp.componentId"
                :data="imageProp"
                v-for="imageProp in imageProperties"
                :key="imageProp.field"
                @clickUpload="showUpload"
                @clickReset="showResetConfirm"
                :propertyResetData="propertyResetData"
            ></image-tile>

            <div class="toning-setting__with-icon-title" v-if="otherProperties.length > 0">
                <div class="title">Other</div>
            </div>

            <other-tile
                :component="otherProp.componentId"
                :data="otherProp"
                v-for="otherProp in otherProperties"
                :key="otherProp.field"
                @changed="submitPropData"></other-tile>
        </div>
    </div>
    </div>
</template>

<script>
import ColorTile from './ColorTile'
import ImageTile from './ImageTile'
import OtherTile from './OtherTile'
import TTInstanceService from '../../services/TTInstanceService'

export default {
  name: 'CommonProperties',
  props: ['propertyResetData'],
  components: {
    ImageTile,
    ColorTile,
    OtherTile
  },
  data () {
    return {
      colorProperties: [],
      imageProperties: [],
      otherProperties: [],
      instanceService: new TTInstanceService()
    }
  },
  computed: {
    trainer () {
      return this.$store.getters.getTrainer
    }
  },
  mounted () {
    this.setCommonProperties()
  },
  methods: {
    setCommonProperties () {
      this.$store.commit('setLoading', true)
      let commonComponents = this.$store.getters['components/getByType']('Common')

      let componentIds = []
      let compFieldsWithIds = []
      Object.keys(commonComponents).forEach(function (compKey) {
        componentIds.push(commonComponents[compKey].id)

        let compProps = commonComponents[compKey].properties
        Object.keys(compProps).forEach(function (compPropKey) {
          if(
            compProps[compPropKey] &&
            compProps[compPropKey].config &&
            compProps[compPropKey].config.visible
          ) {
            compFieldsWithIds[compProps[compPropKey].field] = commonComponents[compKey].id
          }
        })
      })
      let self = this
      this.instanceService.getComponentData(this.$store.getters.getInstance.id, componentIds)
        .then(response => {
          Object.keys(response.data.data).forEach(function (propKey) {
            let prop = response.data.data[propKey]
            prop.componentId = compFieldsWithIds[prop.field]
            let propType = prop.type
            if (propType === 'color') {
              self.colorProperties.push(prop)
            } else if (propType === 'image') {
              self.imageProperties.push(prop)
            } else if (propType === 'other') {
              self.otherProperties.push(prop)
            }
          })
          self.$store.commit('setLoading', false)
        })
        .catch(() => {
          self.$store.commit('setLoading', false)
        })
    },
    
    submitPropData (field, data, componentId, type) {
      this.$emit('submitPropData', field, data, componentId, type)
    },
    showUpload (field, componentId) {
      this.$emit('showUpload', field, componentId)
    },
    showResetConfirm (field, componentId) {
      this.$emit('showResetConfirm', field, componentId)
    }
  }
}
</script>

<style scoped>

</style>
